import { useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import "./Services.css";
import image1 from "../Assets/agirYuk.png";
import image2 from "../Assets/agirYukTasima.png";
import image3 from "../Assets/proje-tsmclk-bnnr.png";
import image4 from "../Assets/depolama-hizmeti.png";

gsap.registerPlugin(ScrollTrigger);

export default function Servic() {
  const main = useRef();

  useGSAP(
    () => {
      const articles = gsap.utils.toArray("article");

      articles.forEach((article, i) => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: article,
            start: "top 40%",
            end: "bottom 40%",
            toggleActions: "play reverse play reverse",
            ease: "power4.out",
            duration: 0.3,
          },
        });

        tl.fromTo(
          article.querySelector("p"),
          {
            opacity: 0,
            y: 20,
            height: 0,
          },
          {
            opacity: 1,
            y: 0,
            height: "auto",
          }
        );

        tl.fromTo(
          article.querySelector("h2"),
          {
            color: "rgba(255,255,255,0.4)",
          },
          {
            color: "rgba(255,255,255,1)",
          },
          0
        );

        tl.fromTo(
          article,
          {
            backgroundColor: "rgba(255,255,255,0)",
            borderLeft: "10px solid rgba(255,255,255,0)",
          },
          {
            backgroundColor: "rgba(255,255,255,0.1)",
            borderLeft: "10px solid rgba(255,255,255,1)",
          },
          0
        );

        tl.fromTo(
          article.querySelector("img"),
          {
            opacity: 0,
            x: 10,
          },
          {
            opacity: 1,
            x: 0,
          },
          0
        );
      });
    },
    { scope: main }
  );

  return (
    <main ref={main} className="yunusMain">
      <section className="yunusSection">
        <div className="container">
          <h4 className="bkBaslik">Hizmetlerimiz</h4>
          <br />
          <h1 className="baslik1">
            Müşteri Odaklı <strong>Taşıma Çözümleri</strong>
          </h1>
        </div>
        {features.map((feature) => (
          <article key={feature.title} className="itemGroup">
            <img
              className="yunusimg"
              src={feature.image}
              alt={feature.title}
              loading="lazy"
            />
            <h2 className="baslik1">{feature.title}</h2>
            <p className="textField">{feature.content}</p>
          </article>
        ))}
      </section>
      <div className="sas dsNone">
        <div className="Services">
          <div className="pinned">
            <div className="container">
              <h4 className="bkBaslik">Hizmetlerimiz</h4>
              <br />
              <h1 className="baslik1">
                Müşteri Odaklı <strong>Taşıma Çözümleri</strong>
              </h1>
            </div>

            {/* mobil */}

            <div className="container ">
              <div className="textGroup">
                {features.map((feature) => (
                  <div className="itemGroup" data-slide="1">
                    <div className="item">
                      <h2 className="baslik1">{feature.title}</h2>
                      <div className="">
                        <p className="text">{feature.content}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

const features = [
  {
    title: "Konteyner Nakliyat",
    content:
      "Zoroğlu Lojistik olarak her boyutta ve özellikteki konteyner ihtiva eden yüklerinizin nakliyatını, profesyonel ekip ve modern ekipmanlarımızla, sunuş noktasından alıp varış noktasına kadar güvenle ve en hızlı şekilde ulaştırıyoruz.",
    image: image1,
  },
  {
    title: "Ağır Yük Taşımacılığı",
    content:
      "Demir çelik, kereste ve geri dönüşüm ürünleri gibi konteyner ile nakliyatı mümkün olmayan her nevi (açık/kapalı)ağır tonajlı yükünüzün nakliyatını gerçekleştiriyoruz.",
    image: image2,
  },
  {
    title: "Proje Taşımacılığı",
    content:
      "Fabrika, depo, işyeri gibi ya da kurulum ekipmanları gibi bütünlük arz eden ve ekstra ekipman gerektiren yüklerinizin nakliyatını, alış noktasından varış noktasına kadar bütünlük dahilinde taşıyoruz.",
    image: image3,
  },
  {
    title: "Depolama Çözümleri",
    content:
      "Ambarlı Limanının hemen yanıbaşında yer alan serbest depomuz ile dolu ve boş konteynerlerinizin ve açık yüklerinizin depolamasını gün ve saat farketmeksizin gerçekleştiriyoruz.",
    image: image4,
  },
];
