import React, { useRef, useLayoutEffect, useEffect } from "react";
import "./Hizmetler.css";
import BreadCrumps from "../BreadCrumps/BreadCrumps";
import ProjeTasima from "../Assets/projeTasimaciligi.jpg";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Helmet, HelmetProvider } from "react-helmet-async";

//animations
import Aos from "aos";
import "aos/dist/aos.css";

const ProjeTasimaciligi = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, []);

  const main = useRef();
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    let mm = gsap.matchMedia();
    mm.add("(min-width: 400px)", () => {
      gsap.utils.toArray(".Hizmetler").forEach((container) => {
        let image = container.querySelector(".full-cover");
        gsap.to(image, {
          y: 100,
          scrollTrigger: {
            start: "top 20%",
            end: "bottom 20%",
            trigger: container,
            id: "pinned",
            scrub: 1,
            invalidateOnRefresh: true,
          },
        });
      });
    });
    return () => mm.revert();
  });
  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>Proje Taşımacılığı | Zoroğlu Lojistik®</title>
          <meta
            name="description"
            content="Tüm Türkiye'ye uygun fiyatlarla proje taşımacılığı yapıyoruz."
          ></meta>
          <meta
            property="og:description"
            content="Tüm Türkiye'ye uygun fiyatlarla proje taşımacılığı yapıyoruz."
          ></meta>
          <meta
            property="og:url"
            content="https://www.zoroglulojistik.com/proje-tasimaciligi/"
          ></meta>
        </Helmet>
        <BreadCrumps
          page="Proje Taşımacılığı"
          baslik="Lojistik İhtiyaçlarınıza Profesyonel Çözümler"
          text="Profesyonel, hızlı ve çözüm odaklı yaklaşım. Makul Proje Nakliyat Teklifleri"
        />
        <div className="Hizmetler">
          <div className="bannerLogoHzmtlr">
            <img
              src="img/zoroglu-z.png"
              alt="Zoroglu logo"
              width="350px"
              loading="lazy"
            />
          </div>
          <div className="container">
            <div
              className="Konteyner"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-anchor-placement="top-bottom"
            >
              <h4 className="bkBaslik">Proje Taşımacılığı</h4>
              <h1 className="baslik1">
                Proje <strong>Taşımacılığı</strong>
              </h1>
              <p className="text1 pb40">
                Proje Taşımacılığı, standart yük ölçülerinin ve ağırlıklarının
                dışında olan eşya, mal ve yüklerin taşınması için tercih edilen
                bir hizmettir. Proje taşımacılığı hizmetimiz sırasında sadece
                özel araçlar ve ekipmanlar kullanılmaktadır. <br />
                <br />
                Klasik yüklerin ölçülerinden daha farklı ağırlık ve boyutlara
                sahip olan yükler, proje taşımacılığı hizmeti sayesinde
                taşınmaktadır. Proje taşımacılığı hizmetimiz hem yurtiçi, hem de
                yurtdışı için yapılabilmektedir. <br />
                <br />
                İhtiyaç duyduğunuz proje taşımacılığı hizmetinde en uygun fiyat
                tekliflerimizden yararlanmak için, firmamız ile{" "}
                <a href="tel:+905412385934">
                  <strong>iletişime geçebilirsiniz.</strong>{" "}
                </a>
              </p>
            </div>
          </div>
          <div className="bgGreen">
            <div className="container">
              <div className="grid2">
                <div className="image-group">
                  <img
                    src={ProjeTasima}
                    alt="Ağır Yük Taşımacılığı"
                    className="full-cover"
                  />
                </div>
                <div className="konteynerText">
                  <h2 className="baslik1">
                    Proje Taşımacılığı Avantajları Nelerdir?
                  </h2>
                  <p className="text1">
                    <ul>
                      <li
                        data-aos="fade-right"
                        data-aos-duration="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <p className="liBaslik">Güvenilirlik</p>
                        Taşınacak olan proje ile ilgili risk ve sevkiyat
                        yönetimi planı yapılmaktadır.
                      </li>
                      <li
                        data-aos="fade-left"
                        data-aos-duration="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <p className="liBaslik">Profesyonel Hizmet</p>
                        Karayolları başta olmak üzere, sevkiyat ve lojistik için
                        gerekli yol izinleri alınmaktadır.{" "}
                      </li>
                      <li
                        data-aos="fade-right"
                        data-aos-duration="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <p className="liBaslik">Maliyet</p>
                        Taşınacak olan projeye uygun teknik ekipmanlar, araçlar
                        ve malzemeler temin edilmektedir.{" "}
                      </li>
                      <li
                        data-aos="fade-left"
                        data-aos-duration="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <p className="liBaslik">Üst Düzey Kalite</p>
                        Proje taşımacılığında yükler teslim edilene kadar,
                        profesyonel ekibimiz tarafından izlenmektedir.{" "}
                      </li>
                      <li
                        data-aos="fade-right"
                        data-aos-duration="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <p className="liBaslik">Üst Düzey Kalite</p>
                        Taşımacılık sektöründe öncü olarak hizmet veren firmamız
                        bünyesinde proje taşımacılığı için uygun ekipman ve
                        araçlar mevcuttur.{" "}
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div
              className="konteynerFiyat"
              data-aos="fade-up"
              data-aos-duration="600"
              data-aos-anchor-placement="top-bottom"
            >
              <h4 className="bkBaslik">Fiyatlar</h4>
              <h1 className="baslik1">
                Proje Taşımacılığı <strong> Fiyatları</strong>
              </h1>
              <p className="text1 pb40">
                Proje taşımacılığı fiyatları pek çok faktöre göre değişmekte ve
                belirlenmektedir. Vergiler, yükü boyutu, hacmi, ağırlığı,
                alınacak olan izinler ve gidilecek olan ülke ve mesafe gibi pek
                çok etken fiyatları değiştirmektedir. <br />
                <br />
                Firmamız ile{" "}
                <a style={{ display: "inline" }} href="tel:0542 261 28 29">
                  <strong>iletişime geçerek,</strong>{" "}
                </a>{" "}
                bütçenize uygun ve kurumsal proje taşımacılığı hizmetimizden
                yararlanabilirsiniz.
              </p>
            </div>
          </div>
        </div>
      </>
    </HelmetProvider>
  );
};

export default ProjeTasimaciligi;
