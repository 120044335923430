import React, { useEffect, useState } from "react";
import "./Footer.css";
import WorldMap from "../Assets/worldMap.png";

const Footer = () => {
  // bu kısım scroll to topun animasyonu için

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  // bu kısım scroll to topun animasyonu için

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer className="footer-field">
      {/* <div className='logoBanner'>
            <img src="./img/bannerLogo.png" alt="Batutrans logo" width="850px" />
          </div> */}
      <div className="footer-top">
        <div className="container">
          <div className="WorldMap">
            <img src={WorldMap} alt="Batutrans logo" width="1150px" />
          </div>
          <div className="wrapper-grid">
            <div className="logo-field"></div>
            <div class="footerSplit w-full h-[1px] border border-solid "></div>
          </div>
          <div className="wrapper-grid">
            <div className="footer-contact">
              <div className="contact-info">
                <ul className="contacts">
                  <li>
                    <div className="logo-field">
                      <img src="./img/zoroglu-logo3.png" alt="" width="100%" />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="social-media-field">
                <span className="title">Sosyal Medya</span>
                <ul className="flex-wrapF">
                  <li>
                    <a href="/">
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i class="fa-brands fa-x-twitter"></i>
                    </a>
                  </li>
                  <li></li>
                </ul>
              </div>
            </div>
            <div className="wrapper-grid3">
              <div className="footer-menu">
                <div className="footer-menu-content">
                  <ul>
                    <li>
                      <a href="tel:+905412385934">
                        <span className="title">Telefon</span>
                        <span className="text">+(90) 541 238 5934</span>
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@zoroglulojistik.com">
                        <span className="title">E-Mail</span>
                        <span className="text">info@zoroglulojistik.com</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="">
                <div className="">
                  <ul>
                    <li>
                      <a href="">
                        <div className="adres">
                          <span className="title ">Adres</span>
                          <span className="text">
                            <span>Ambarlı Limanı </span>Beylikdüzü/İstanbul
                          </span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="up-btn-area" onClick={scrollToTop}>
                <div className="up-btn btn--3 ">
                  <div className="upIcon">
                    <i class="fa-solid fa-arrow-up "></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div
          className="container"
          style={{ paddingBottom: "0", paddingTop: "0" }}
        >
          <div className="footerSplit"></div>
          <div className="wrapper-text">
            <div>
              <p className="copyrightText">
                Copyright &copy; 2024 zoroglulojistik.com
              </p>
            </div>
            <div className="signature">
              <a
                href="https://www.plazayazilim.com"
                title="Web Tasarım Firması"
                rel="dofollow"
              >
                Plaza Yazılım
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
