import React, { useEffect } from "react";
import "./BizKimiz.css";
import TruckBanner2 from "../Assets/truckBanner2.png";

//animations
import Aos from "aos";
import "aos/dist/aos.css";

const BizKimiz = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, []);

  return (
    <div className="bizKimiz">
      <div className="container tanitim">
        <div
          className="bkİcerik"
          style={{ marginBottom: "50px", marginTop: "50px" }}
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-anchor-placement="top-bottom"
        >
          <div className="sekiller"></div>
          <h4 className="bkBaslik">Bizi Tanıyın</h4>
          <h1>
            Lojistik Sektörünün Gelişen Yüzü<strong> Zoroğlu Lojistik</strong>
          </h1>
          <p className="text1 pb40">
            2018 yılından beridir lojistik sektöründe aracılık hizmeti veren
            Zoroğlu Lojistik, kurumsal ve bireysel müşterilerine; konteyner
            taşıma, ağır ve açık yük nakliyatı ve proje taşımacılığı alanlarında
            profesyonel işbirliği hizmeti sunmaktadır. İstanbul merkezli hizmet
            veren firmamız Marmara bölgesi ağırlıklı olmak üzere ulusal çapta,
            evrensel kalite standartlarında aracı lojistik hizmeti sunuyor.
            Profesyonel, tecrübeli ekibi ve modern nakliyat teçhizatlara sahip
            iş ortakları ile ülkemizin geleceğine katkıda bulunuyor.
          </p>
          <div class="text-wrapper relative z-20">
            <div class="button-field flex flex-wrap  gap-[25px] max-w-[500px] ">
              <a
                href="/kurumsal"
                class="btn btn--2 button flex items-center group w-max h-65 xl:h-50 bg-white/15  relative transition-all !duration-500 ease-samedown overflow-hidden isolate xs:justify-center before:bg-primary-900  "
                style={{ background: "#2A4FF6" }}
              >
                <div class="text text-[20px] xl:text-18 sm:text-16 font-medium flex items-center text-white relative z-2 w-full h-full px-35 xl:px-25">
                  Hakkımızda
                </div>
                <div class="icon-animate icon-arrow-right-short text-[12px] flex items-center text-[#676767] relative z-2 duration-500 ease-samedown group-hover:translate-x-1 ">
                  <div class="icon relative flex justify-center items-center z-2 bg-white/15 w-full h-65 xl:h-50 px-25 xl:px-18">
                    <i class="fa-solid fa-chevron-right icon-animate icon-chevron-right h-[12px] text-[12px] text-white/25  duration-500 relative z-20 flex justify-center items-center"></i>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          className="truckBanner"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-anchor-placement="top-bottom"
        >
          <img src={TruckBanner2} alt="Tir Banner" width="100%" height="100%" />
        </div>
        <div className="bannerLogo">
          <img
            src="img/zoroglu-z.png"
            alt="Zoroglu logo"
            width="350px"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default BizKimiz;
