import React, { useRef, useLayoutEffect, useEffect } from "react";
import "./Hizmetler.css";
import BreadCrumps from "../BreadCrumps/BreadCrumps";
import AgirYuk from "../Assets/agirYuk.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Helmet, HelmetProvider } from "react-helmet-async";
//animations
import Aos from "aos";
import "aos/dist/aos.css";

const Hizmetler = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, []);

  const main = useRef();
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    let mm = gsap.matchMedia();
    mm.add("(min-width: 400px)", () => {
      gsap.utils.toArray(".Hizmetler").forEach((container) => {
        let image = container.querySelector(".full-cover");
        gsap.to(image, {
          y: 100,
          scrollTrigger: {
            start: "top 20%",
            end: "bottom 20%",
            trigger: container,
            id: "pinned",
            scrub: 1,
            invalidateOnRefresh: true,
          },
        });
      });
    });
    return () => mm.revert();
  });

  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>Konteyner Taşımacılığı | Zoroğlu Lojistik®</title>
          <meta
            name="description"
            content="Tüm Türkiye'ye uygun fiyatlarla Konteyner Taşımacılığı yapıyoruz."
          ></meta>
          <meta
            property="og:description"
            content="Tüm Türkiye'ye uygun fiyatlarla Konteyner Taşımacılığı yapıyoruz."
          ></meta>
          <meta
            property="og:url"
            content="https://www.zoroglulojistik.com/konteyner-tasimaciligi/"
          ></meta>
        </Helmet>
        <BreadCrumps
          page="Konteyner Taşımacılığı"
          baslik="Lojistik İhtiyaçlarınıza Profesyonel Çözümler"
          text="Profesyonel, hızlı ve çözüm odaklı yaklaşım. Uygun Konteyner Nakliyat Teklifleri"
        />
        <div className="Hizmetler">
          <div className="bannerLogoHzmtlr">
            <img
              src="img/zoroglu-z.png"
              alt="Zoroglu logo"
              width="350px"
              loading="lazy"
            />
          </div>
          <div className="container">
            <div
              className="Konteyner"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-anchor-placement="top-bottom"
            >
              <h4 className="bkBaslik">Konteyner Taşımacılığı</h4>
              <h1 className="baslik1">
                Konteyner <strong>Taşımacılığı</strong>
              </h1>
              <p className="text1 pb40">
                Karayolu konteyner taşımacılığı profesyonel firmalar ve uzman
                bir ekip tarafından yapılması gereken bir lojistik hizmetidir.
                Ülkemizde en çok ihtiyaç duyulan taşımacılık hizmetleri arasında
                yer alır. Özel olarak tasarlanmış olan konteyner taşıma aracı
                çeşitleri sayesinde, ürünleriniz hızlı ve güvenilir şekilde
                dilediğiniz göre gönderilebilmektedir. Karayolu konteyner
                taşımacılık hizmetimiz oldukça güvenlidir. <br />
                <br />
                Kurumsal olarak hizmet veren firmamız, 7/24 takip sistemi
                kullanarak konteyner taşımacılığı alanında anlık kontrol sistemi
                ile çalışmaktadır. Bu sayede taşıma işlemlerinizde her an
                firmamız kontrolünde gerçekleşmektedir. Konteyner ile ne taşınır
                ve taşımacılık hizmet fiyatlarımız hakkında bilgi almak için
                bizlerle iletişime geçebilirsiniz.
              </p>
            </div>
          </div>
          <div className="bgGreen">
            <div className="container">
              <div className="grid2">
                <div className="image-group">
                  <img
                    src={AgirYuk}
                    alt="Ağır Yük Taşımacılığı"
                    className="full-cover"
                    loading="lazy"
                  />
                </div>
                <div className="konteynerText">
                  <h2 className="baslik1">
                    Konteyner Taşımacılığı Avantajları Nelerdir?
                  </h2>
                  <p className="text1">
                    <ul>
                      <li
                        data-aos="fade-right"
                        data-aos-duration="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <p className="liBaslik">Güvenilirlik</p>
                        Konteyner taşımacılığı, özellikle en güvenilir ve
                        dayanıklı taşımacılık türüdür. Kargoların tek bir
                        seferde ve aktarılmadan taşınmasını sağlar.
                      </li>
                      <li
                        data-aos="fade-left"
                        data-aos-duration="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <p className="liBaslik">Süreklilik</p>
                        Konteyner çeşitlerinin süreklilik özellikleri
                        avantajlıdır. Oldukça dayanıklı bir yapıya sahip olduğu
                        için, sürekli ve tekrar tekrar taşıma işlemlerinde
                        kullanılabilir.{" "}
                      </li>
                      <li
                        data-aos="fade-right"
                        data-aos-duration="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <p className="liBaslik">Tasarruf</p>
                        Konteyner taşımacılığı özellikle zaman açısından sizlere
                        tasarruf sunmaktadır. Güvenli ve kısa zamanda
                        taşımacılık yöntemi için tercih edilmektedir.{" "}
                      </li>
                      <li
                        data-aos="fade-left"
                        data-aos-duration="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <p className="liBaslik">Maliyet</p>
                        Taşımacılık sektöründe en önemli konulardan biri de
                        maliyettir. Konteyner taşımacılığı fiyatları ise,
                        sunulan hizmete göre oldukça ekonomiktir. Denizyolu veya
                        karayolu taşımacılığı ile yapılan diğer lojistik
                        türlerine göre, konteyner taşımacılığında maliyet ciddi
                        oranlarda düşmektedir.{" "}
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div
              className="konteynerFiyat"
              data-aos="fade-up"
              data-aos-duration="600"
              data-aos-anchor-placement="top-bottom"
            >
              <h4 className="bkBaslik">Fiyatlar</h4>
              <h1 className="baslik1">
                Konteyner <strong>Taşıma Fiyatları</strong>
              </h1>
              <p className="text1 pb40">
                Konteyner taşıma fiyatları bazı kriterlere göre farklılık
                göstermektedir. Öncelikli olarak, her taşımacılık firmasının,
                kendi politikalarına göre konteyner taşımacılığı fiyatı
                belirlediğini söyleme mümkündür. Bunun dışında yurtdışı veya
                yurtiçi gönderilerinizde de fiyatlar büyük ölçüde değişmektedir.{" "}
                <br />
                <br />
                Taşınacak olan malın ağırlığı, boyutu, liman seçimi, taşma
                süresi ve konteyner sayısına göre konteyner taşımacılığı
                fiyatları değişmektedir. 20’lik konteyner nakliye fiyatları 2024
                yılı için en ekonomik tekliflerimizden yararlanabilirsiniz.{" "}
                <br />
                <br />
                Karayolu, deniz, hava ve demiryolu taşımacılığında güvenli ve
                uygun fiyatlı hizmet almak için doğru yerdesiniz. Her zaman
                yenilikçi ve çözüm odaklı olarak hizmet sunan ekibimiz
                sayesinde, tüm taşımacılık hizmetlerimizden yararlanabilirsiniz.{" "}
                <br />
                <br />
                Konteyner taşıma hizmetimizden yararlanmak için, uzman kadromuz
                ile görüşebilir ve size uygun lojistik hizmetlerimizden
                faydalanabilirsiniz. firmamızı arayarak, konteyner taşımacılığı
                hizmetimiz ve fiyatlarımız hakkında{" "}
                <a href="tel:+905412385934">
                  <strong>detaylı bilgi alabilirsiniz.</strong>{" "}
                </a>
              </p>
            </div>
          </div>
        </div>
      </>
    </HelmetProvider>
  );
};

export default Hizmetler;
