import React from "react";
import "./Dot.css";
import KonteynerLogo from "../Assets/konteyner-logo (3).png";
const Dot = () => {
  return (
    <div className="Dot">
      <div
        className=" DtTanitim"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="transportBanner">
          <img src={KonteynerLogo} alt="" />
        </div>
        <div
          className="container bkİcerik"
          style={{ marginBottom: "50px", marginTop: "50px" }}
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-anchor-placement="top-bottom"
        >
          <h4 className="bkBaslik">Lojistiğin Gelişen Yüzü</h4>
          <h1>
            <strong>Zoroğlu Lojistik</strong> Olarak İhtiyaçlarınızı Önemsiyor
            Yükünüzü Hafifletiyoruz
          </h1>
          <p className="text1 pb40">
            Gelişen ve genişleyen sektör dinamiklerini ve ihtiyaçlarını analiz
            ediyor; müşterilerimizin taleplerini dinliyor, çözüm odaklı
            yaklaşıyor ve profesyonel ve uygun teklifler sunuyoruz. Ülkemizin
            parlak geleceğine yatırım yapıyor ve lojistik sektörüne
            değer katıyoruz.
          </p>
          <div class="text-wrapper relative z-20">
            <div class="button-field flex flex-wrap  gap-[25px] max-w-[500px] ">
              <a
                href="/konteyner-tasimaciligi"
                class="btn btn--2 button flex items-center group w-max h-65 xl:h-50 bg-white/15  relative transition-all !duration-500 ease-samedown overflow-hidden isolate xs:justify-center before:bg-primary-900  "
                style={{ background: "#2A4FF6" }}
              >
                <div class="text text-[20px] xl:text-18 sm:text-16 font-medium flex items-center text-white relative z-2 w-full h-full px-35 xl:px-25">
                  Hizmetler
                </div>
                <div class="icon-animate icon-arrow-right-short text-[12px] flex items-center text-[#676767] relative z-2 duration-500 ease-samedown group-hover:translate-x-1 ">
                  <div class="icon relative flex justify-center items-center z-2 bg-white/15 w-full h-65 xl:h-50 px-25 xl:px-18">
                    <i class="fa-solid fa-chevron-right icon-animate icon-chevron-right h-[12px] text-[12px] text-white/25  duration-500 relative z-20 flex justify-center items-center"></i>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dot;
