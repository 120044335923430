import React, { useRef, useEffect } from "react";
import "./Home.css";
import BizKimiz from "../BizKimiz/BizKimiz";
import Services from "../Services/Services";
import Dot from "../Dot/Dot";
import Referances from "../Referances/Referances";
import Servic from "../Services/Servic";

//animations
import Aos from "aos";
import "aos/dist/aos.css";

const Home = () => {
  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, []);

  return (
    <>
      <div className="home">
        <div className="container">
          <div className="homeText">
            <h2
              className="headerbaslik"
              data-aos="zoom-in"
              data-aos-duration="500"
            >
              Taşıma sektöründe öncü <strong> hizmet anlayışı</strong>{" "}
            </h2>
            <h4
              className="sloganText"
              data-aos="zoom-in"
              data-aos-duration="700"
            >
              Lojistik sektöründe üstün hizmet anlayışımızı deneyimlemek için
              bizimle iletişime geçin.
            </h4>
            <div
              class="text-wrapper relative z-20"
              data-aos="zoom-out"
              data-aos-duration="750"
              data-aos-anchor-placement="top-bottom"
            >
              <div class="button-field flex flex-wrap  gap-[25px] max-w-[500px] ">
                <a
                  href="tel:+905412385934"
                  class="btn btn--2 button flex items-center group w-max h-65 xl:h-50 bg-white/15  relative transition-all !duration-500 ease-samedown overflow-hidden isolate xs:justify-center before:bg-primary-900  "
                >
                  <div class="text text-[20px] xl:text-18 sm:text-16 font-medium flex items-center text-white relative z-2 w-full h-full px-35 xl:px-25">
                    En Uygun Teklifi Al
                  </div>
                  <div class="icon-animate icon-arrow-right-short text-[12px] flex items-center text-[#676767] relative z-2 duration-500 ease-samedown group-hover:translate-x-1 ">
                    <div class="icon relative flex justify-center items-center z-2 bg-white/15 w-full h-65 xl:h-50 px-25 xl:px-18">
                      <i class="fa-solid fa-chevron-right icon-animate icon-chevron-right h-[12px] text-[12px] text-white/25  duration-500 relative z-20 flex justify-center items-center"></i>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="scroll-down-animation" onClick={scrollToBottom}>
          <span class="mouse" ref={bottomRef}>
            <span class="move"></span>
          </span>
        </div>
      </div>
      <BizKimiz />
      {/* <Services/> */}
      <Servic />
      <Dot />
      <Referances />
    </>
  );
};

export default Home;
