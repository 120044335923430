import React, { useState, useEffect } from "react";
import "./CounterUp.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const CounterUp = () => {
  const [counted, setCounted] = useState(false);

  useEffect(() => {
    // ScrollTrigger'dan gelen tetiklemeleri kullanarak sayaçların bir kere çalışmasını sağlayalım
    const triggerCount = () => {
      if (!counted) {
        setCounted(true);
        window.removeEventListener("scroll", triggerCount);
      }
    };

    window.addEventListener("scroll", triggerCount);

    return () => {
      window.removeEventListener("scroll", triggerCount);
    };
  }, [counted]);

  return (
    <div className="CounterUp">
      <div className="container">
        <div className="content">
          <div className="box">
            <ScrollTrigger
              onEnter={() => setCounted(true)}
              onExit={() => setCounted(false)}
            >
              <div className="counter baslik">
                +{" "}
                {counted && (
                  <CountUp start={0} end={6} duration={2} delay={0} />
                )}{" "}
              </div>
            </ScrollTrigger>
            <div className="counterText cardBaslik">
              Yıllık <strong>Deneyim</strong>
            </div>
          </div>
          <div className="box">
            <ScrollTrigger
              onEnter={() => setCounted(true)}
              onExit={() => setCounted(false)}
            >
              <div className="counter baslik">
                {" "}
                +{" "}
                {counted && (
                  <CountUp start={0} end={200} duration={2} delay={0} />
                )}{" "}
              </div>
            </ScrollTrigger>
            <div className="counterText cardBaslik">
              Kurumsal <strong>Firma</strong>{" "}
            </div>
          </div>
          <div className="box">
            <ScrollTrigger
              onEnter={() => setCounted(true)}
              onExit={() => setCounted(false)}
            >
              <div className="counter baslik">
                +{" "}
                {counted && (
                  <CountUp start={0} end={500} duration={2} delay={0} />
                )}{" "}
              </div>
            </ScrollTrigger>
            <div className="counterText ">
              Bireysel <strong>Müşteri</strong>
            </div>
          </div>
          <div className="box">
            <ScrollTrigger
              onEnter={() => setCounted(true)}
              onExit={() => setCounted(false)}
            >
              <div className="counter baslik">
                +{" "}
                {counted && (
                  <CountUp start={0} end={80} duration={2} delay={0} />
                )}{" "}
              </div>
            </ScrollTrigger>
            <div className="counterText cardBaslik">
              Modern <strong>Ekipman</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterUp;
