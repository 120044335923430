import React, { useState } from "react";
import emailjs from "@emailjs/browser";

import "./Contact.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_v45le6x";
    const templateId = "template_k6l97uc";
    const userId = "7qmwI-FhI3hgSGtfL";

    const templateParams = {
      from_name: name + " " + surname,
      from_email: email,
      to_name: "BatuTransLojistik",
      message: message,
      number: number,
    };

    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then((response) => {
        console.log("E-mail başarıyla gönderildi!", response);
        setName("");
        setSurname("");
        setEmail("");
        setNumber("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Mesaj Gönderilemedi:", error);
      });
  };

  return (
    <div className="container">
      <div className="innerwrap" style={{ marginTop: "100px" }}>
        <section className="section1 clearfix">
          <div className="textcenter">
            <span className="baslik1">İletişim</span>
            <span className="seperator"></span>
            <h1>İletişim Formu</h1>
          </div>
        </section>

        <section className="section2 clearfix">
          <div className="col2 column1 first">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.5649073491245!2d28.67339401172188!3d40.96910942171611!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b55fbfffffffff%3A0x63fe67fd19c7313!2sAlta%C5%9F%20Ambarl%C4%B1%20Liman%20Tesisleri!5e0!3m2!1str!2str!4v1718306793277!5m2!1str!2str"
              width="100%"
              height="600"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="col2 column2 last">
            <div className="sec2innercont">
              <div className="sec2addr">
                {/* Adres ve İletişim Bilgileri */}
              </div>
            </div>
            <div className="sec2contactform">
              <div className="contactTel">
                <h4> Telefon</h4>
                <a href="tel:+905412385934">+90 541 238 5934</a>
                <h4> E-Posta</h4>
                <a href="mailto:info@zoroglulojistik.com ">
                  info@zoroglulojistik.com
                </a>
                <img src="./img/zoroglu-z3.png" alt="" />
              </div>
              <h3 className="sec2frmtitle">
                Bizimle İletişime Geçebilirsiniz !
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="clearfix">
                  <input
                    className="col2 first"
                    type="text"
                    placeholder="Adınız"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    className="col2 last"
                    type="text"
                    placeholder="Soyadınız"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                  />
                </div>
                <div className="clearfix">
                  <input
                    className="col2 first"
                    type="email"
                    placeholder="E-Posta"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    className="col2 last"
                    type="text"
                    placeholder="İletişim Numarası"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
                <div className="clearfix">
                  <textarea
                    name="message"
                    cols="30"
                    rows="7"
                    placeholder="Mesajınızı Giriniz..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div class="text-wrapper relative z-20">
                  <div class="button-field flex flex-wrap  gap-[25px] max-w-[500px] ">
                    <button
                      type="submit"
                      class="btn btn--2 button flex items-center group w-max h-65 xl:h-50 bg-white/15  relative transition-all !duration-500 ease-samedown overflow-hidden isolate xs:justify-center before:bg-primary-900  "
                      style={{ background: "#2a4ff6" }}
                    >
                      <div class="text text-[20px] xl:text-18 sm:text-16 font-medium flex items-center text-white relative z-2 w-full h-full px-35 xl:px-25">
                        Gönder
                      </div>
                      <div class="icon-animate icon-arrow-right-short text-[12px] flex items-center text-[#676767] relative z-2 duration-500 ease-samedown group-hover:translate-x-1 ">
                        <div class="icon relative flex justify-center items-center z-2 bg-white/15 w-full h-65 xl:h-50 px-25 xl:px-18">
                          <i class="fa-solid fa-chevron-right icon-animate icon-chevron-right h-[12px] text-[12px] text-white/25  duration-500 relative z-20 flex justify-center items-center"></i>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;
