import React, { useRef, useLayoutEffect, useEffect } from "react";
import "./Hizmetler.css";
import BreadCrumps from "../BreadCrumps/BreadCrumps";
import AgirYukTasima from "../Assets/agirYukTasima.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Helmet, HelmetProvider } from "react-helmet-async";
//animations
import Aos from "aos";
import "aos/dist/aos.css";

const AgirYuk = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, []);

  const main = useRef();
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    let mm = gsap.matchMedia();
    mm.add("(min-width: 800px)", () => {
      gsap.utils.toArray(".Hizmetler").forEach((container) => {
        let image = container.querySelector(".full-cover");
        gsap.to(image, {
          y: 100,
          scrollTrigger: {
            start: "top 20%",
            end: "bottom 20%",
            trigger: container,
            id: "pinned",
            scrub: 1,
            invalidateOnRefresh: true,
          },
        });
      });
    });
    return () => mm.revert();
  });
  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>Ağır Yük Taşımacılığı | Zoroğlu Lojistik®</title>
          <meta
            name="description"
            content="Tüm Türkiye'ye uygun fiyatlarla Ağır Yük Taşımacılığı yapıyoruz."
          ></meta>
          <meta
            property="og:description"
            content="Tüm Türkiye'ye uygun fiyatlarla Ağır Yük Taşımacılığı yapıyoruz."
          ></meta>
          <meta
            property="og:url"
            content="https://www.zoroglulojistik.com/agir-yuk-tasimaciligi/"
          ></meta>
        </Helmet>
        <BreadCrumps
          page="Ağır Yük Taşımacılığı"
          baslik="Lojistik İhtiyaçlarınıza Profesyonel Çözümler"
          text="Profesyonel, hızlı ve çözüm odaklı yaklaşım. Uygun Ağır Nakliyat Teklifleri"
        />
        <div className="Hizmetler">
          <div className="bannerLogoHzmtlr">
            <img
              src="img/zoroglu-z.png"
              alt="Zoroglu logo"
              width="350px"
              loading="lazy"
            />
          </div>
          <div className="container">
            <div
              className="Konteyner"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-anchor-placement="top-bottom"
            >
              <h4 className="bkBaslik">Ağır Yük Taşımacılığı</h4>
              <h1 className="baslik1">
                Ağır<strong> Yük Taşımacılığı</strong>
              </h1>
              <p className="text1 pb40">
                Ağır yük taşımacılığı nedir ve özellikleri hakkında bilgi sahibi
                olmak için doğru yerdesiniz. Ağır yük taşımacılığı sırasında tır
                veya kamyon ile taşınamayacak boyut ve hacimde olan yükler
                transfer edilmektedir <br />
                <br />
                Ağır yük taşımacılığı hem yurtiçi, hem de yurtdışı için uygun
                bir lojistik yöntemidir. Ağır yük taşımacılığı hizmeti
                kesinlikle kurumsal ve profesyonel firmalara tarafından
                gerçekleştirilmelidir. <br />
                <br />
                Ağır yüklerin daha kısa zaman içerisinde ve güvenli bir şekilde
                transfer edilmesi sağlanmalıdır. Bunun için en uygun ağır yük
                taşma araçları ve ekipmanları kullanılmalıdır. Lowbed gibi özel
                araçlar, ağır yük taşımacılığı için kullanılmaktadır.
              </p>
            </div>
          </div>
          <div className="bgGreen">
            <div className="container">
              <div className="grid2">
                <div className="image-group">
                  <img
                    src={AgirYukTasima}
                    alt="Ağır Yük Taşımacılığı"
                    className="full-cover"
                    loading="lazy"
                  />
                </div>
                <div className="konteynerText">
                  <h2 className="baslik1">
                    Ağır Yük Taşımacılığı Avantajları Nelerdir?
                  </h2>
                  <p className="text1">
                    <ul>
                      <li
                        data-aos="fade-right"
                        data-aos-duration="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <p className="liBaslik">Güvenilirlik</p>
                        Ağır yük taşımacılık hizmeti kapsamında taşınacak olan
                        yüklerinizin hepsi, firma sigortamız kapsamındadır.
                        Güvenli bir taşımacılık hizmetinden yararlanarak, tük
                        taşımacılığı işlemlerinizi riske atmazsınız
                      </li>
                      <li
                        data-aos="fade-left"
                        data-aos-duration="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <p className="liBaslik">Profesyonel Hizmet</p>
                        Firmamız, ağır yük taşımacılığı başta olmak üzere tüm
                        hizmetlerinde uzman bir ekip ve profesyonel araç ve
                        ekipmanlar ile birlikte çalışmaktadır. Kurumsal bir
                        lojistik firması ile çalışarak, gönderilerinizin hızlı
                        ve hasar görmeden iletilmesini sağlayabilirsiniz.{" "}
                      </li>
                      <li
                        data-aos="fade-right"
                        data-aos-duration="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <p className="liBaslik">Maliyet</p>
                        Firmamız bünyesinde her zaman, bütçe dostu ve ekonomik
                        fiyat teklifleri alabilirsiniz. Ağır yük taşımacılığı
                        gibi çok maliyetli olabilecek bir lojistik hizmetinde
                        ekonomik seçim yapmak için, firmamızı tercih
                        edebilirsiniz.{" "}
                      </li>
                      <li
                        data-aos="fade-left"
                        data-aos-duration="600"
                        data-aos-anchor-placement="top-bottom"
                      >
                        <p className="liBaslik">Üst Düzey Kalite</p>
                        Taşınacak olan yükün boyutu veya hacmi ne olursa olsun,
                        firmamız aracılığı ile en yüksek kalitede taşıma işlemi
                        gerçekleştirilmektedir.{" "}
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div
              className="konteynerFiyat"
              data-aos="fade-up"
              data-aos-duration="600"
              data-aos-anchor-placement="top-bottom"
            >
              <h4 className="bkBaslik">Fiyatlar</h4>
              <h1 className="baslik1">
                Ağır Yük <strong>Taşıma Fiyatları</strong>
              </h1>
              <p className="text1 pb40">
                Ağır yük taşıma fiyatları ve size özel fiyat tekliflerimizden
                yararlanmak için, firmamız ile iletişime geçebilirsiniz.
                Batutrans firmamıza, 7/24 ulaşabilir ve ekibimizden dilediğiniz
                zaman destek isteyebilirsiniz. <br />
                <br />
                Ağır yük taşıma fiyatları taşınacak olan yüke, mesafeye ve
                kullanılacak olan güzergâha göre farklılık gösterebilmektedir.
                Özellikle Ağır yük taşımacılığında hangi ulaşım seçeneğinin
                kullanılacağı da fiyatlara büyük ölçüde etki etmektedir. <br />
                <br />
                Karayolu, deniz, hava ve demiryolu taşımacılığında güvenli ve
                uygun fiyatlı hizmet almak için doğru yerdesiniz. Her zaman
                yenilikçi ve çözüm odaklı olarak hizmet sunan ekibimiz
                sayesinde, tüm taşımacılık hizmetlerimizden yararlanabilirsiniz.{" "}
                <br />
                <br />
                Kara yolu ile Ağır yük taşıma hizmet fiyatlarımız hakkında
                teklif almak içini dilediğiniz zaman{" "}
                <a href="tel:+905412385934">
                  <strong>bizlerle iletişime geçebilirsiniz.</strong>{" "}
                </a>
              </p>
            </div>
          </div>
        </div>
      </>
    </HelmetProvider>
  );
};

export default AgirYuk;
