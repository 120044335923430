import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
// Pages
import Home from "./components/Home/Home";
import Footer from "./components/Layout/Footer";
import Navbar from "./components/Layout/Navbar";
import About from "./components/About/About"
import Hizmetler from "./components/Hizmetler/Hizmetler"
import Contact from "./components/Contact/Contact";
import AgirYuk from "./components/Hizmetler/AgirYuk";
import ProjeTasimaciligi from "./components/Hizmetler/ProjeTasimaciligi";
import WpButton from "./components/WpButton/WpButton";
const router = createBrowserRouter([
  {
    path:'/',
    element:<Navbar />,
    children:[
    {index:true,element: (<Home />),},
    {path: "/anasayfa",element: (<Home />),},
    {path: "/kurumsal",element: (<About />),},
    {path: "/iletisim",element: (<Contact />),},
    {path: "/konteyner-tasimaciligi",element: (<Hizmetler />),},
    {path: "/agir-yuk-tasimaciligi",element: (<AgirYuk />),},
    {path: "/proje-tasimaciligi",element: (<ProjeTasimaciligi />),},
    
  ]
  }
])

function App() {
  return (
    <div>
    <div>
      <RouterProvider router={router} />
      <WpButton/>
      <Footer/>
  </div>
  </div>
  );
}

export default App;
