import React, { useState, useEffect } from "react";
import "./About.css";
import BreadCrumps from "../BreadCrumps/BreadCrumps";
import CounterUp from "../CounterUp/CounterUp";

//animations
import Aos from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, []);

  return (
    <>
      <BreadCrumps
        baslik="Lojistik Sektörüne Değer Katıyoruz"
        text="Zoroğlu Lojistik olarak onlarca kurumsal , yüzlerce bireysel mutlu müşterilerimizle büyümeye ve gelişmeye devam ediyoruz "
        page="Kurumsal"
      />
      <div className="about">
        <div className="container">
          <div className="about-field">
            <div
              className="bklIcerik"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-anchor-placement="top-bottom"
            >
              <h4 className="bkBaslik">Bizi Tanıyın</h4>
              <h1>
                Uzman Kadromuz ve Modern Ekipmanlarımızla{" "}
                <strong> Lojistik Sektörüne Değer Katıyoruz</strong>{" "}
              </h1>
              <p className="text1 pb40">
                Hikayemiz 2018 yılında Beylikdüzü Beyaş Tır Parkında Nakliyenin
                önde gelen isimleriyle işbirliği yapmamızla başladı. Düşük
                bütçeyle yüksek verimlilik felsefesini ilke edinen firmamız,
                felsefesine bağlı kalarak işbirliği yelpazesini genişletme ve
                daha profesyonel nakliyat hizmeti sunma amacıyla aracı lojistik
                şirketi olarak hizmet vermeye başladı. Bu doğrultuda firmamız
                ağır tonajlı ekipmanlara sahip olan ve deneyimli personelleri
                bünyesinde barındıran, güvenilir şirketlerle işbirliğine gitti.
                Bununla beraber serbest depolama hizmeti veren şirketleri de
                bünyesine kattı. Zoroğlu Lojistik olarak günümüzde, binlerce
                kurumsal ve bireysel memnun müşteri ailemizle lojistik
                sektöründe güvenilir iş ortağı olarak hizmet vermenin haklı
                gururunu yaşıyoruz.
              </p>
            </div>
          </div>
        </div>
        <div className="vm-background">
          <div className="container" style={{ marginBottom: "6rem" }}>
            <div className="vm-field">
              <div className="sekiller"></div>
              <div className="vyznSplit"></div>
              <div className="vzyn-field">
                <div
                  className="vizyon-group"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-anchor-placement="top-bottom"
                >
                  <h1>Vizyon</h1>
                  <p className="text1">
                    Güven ve memnuniyet odaklı hizmet anlayışımızla, gelişen ve
                    genişleyen lojistik sektörünün dinamiklerine ayak uydurarak,
                    sektöründe öncü ve yol gösterici şirket olmak. Değer yaratan
                    olarak ülkemizin ve lojistik sektörünün geleceğine kalıcı
                    katkı sağlamak; örnek alınan ve yol gösteren olmak.
                  </p>
                </div>
                <div
                  className="vizyon-group"
                  data-aos="fade-up"
                  data-aos-duration="700"
                  data-aos-anchor-placement="top-bottom"
                >
                  <h1>Misyon</h1>
                  <p className="text1">
                    Sektörün dinamiklerini takip eden, lojistik ihtiyaçlarına
                    kapsamlı ve kalıcı çözümler üreten; bu doğrultuda gündemi ve
                    kaynakları takip ve analiz eden gelişmeye ve genişlemeye
                    açık firma olmak. Nihayetinde portföyü dahilinde olan insan
                    gücü ve ekipmanmanlarının moderniteye uygun gelişmesine ve
                    genişlemesine yatırım yapan; katma değer yaratan olmak.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CounterUp />
      </div>
    </>
  );
};

export default About;
