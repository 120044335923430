import React from "react";
import "./Referances.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Asoy from "../Assets/asoy-site-logo.png";
import Akademi from "../Assets/akademi.png";
import Polimer from "../Assets/batı polimer - Kopya.png";
import Dag from "../Assets/dag.png";
import Desen from "../Assets/desen.png";
import Ems from "../Assets/ems_yapi_logo.png";
import Etken from "../Assets/etken.png";
import Erol from "../Assets/erol.png";
import Guven from "../Assets/guven.png";

const Referances = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="Referance" id="reference">
      <div className="container">
        <div className="slider" name="reference">
          <div className="slideHeader ">
            <h2 className="baslik1">Bazı Çözüm Ortaklarımız</h2>
          </div>
          <div className="container slidelogo">
            <Slider {...settings}>
              <img
                className="logos"
                src={Akademi}
                alt="akademi logo"
                width="160px"
                height="100px"
              />
              <img
                className="logos"
                src={Asoy}
                alt="asoy logo"
                width="160px"
                height="100px"
              />
              <img
                className="logos"
                src={Guven}
                alt="guven logo"
                width="160px"
                height="100px"
              />
              <img
                className="logos"
                src={Ems}
                alt="ems yapi logo"
                width="160px"
                height="100px"
              />
              <img
                className="logos"
                src={Etken}
                alt="etkenlogo"
                width="160px"
                height="100px"
              />
              <img
                className="logos"
                src={Erol}
                alt="erol lojistik logo"
                width="160px"
                height="100px"
              />
              <img
                className="logos"
                src={Desen}
                alt="desen logo"
                width="160px"
                height="100px"
              />
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referances;
